<template>
  <div id="footer" class="footer" v-if="show">
    <div class="footer-content">
      <div class="right">
        <img
          alt="Games-logo"
          class="logo"
          :src="info[$pathName].src"
        />
        <!-- <p>Công ty Cổ phần</p> -->
      </div>
      <div class="footer_info">
        <p>
          <strong>Địa chỉ:</strong> {{info[$pathName].address}}
        </p>
        <!-- <p>
          <strong>Hỗ trợ:</strong>
          <a href="https://hotro.zing.vn" target="_blank"
            >https://hotro.zing.vn</a
          >
        </p>
        <p><strong>Hotline:</strong> 1900 561 558</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      show:true,
      info:{
        mgol:{
          src:require('../assets/mgolLogo.png'),
          address:'Giấy phép cung cấp dịch vụ trò chơi điện tử G1 trên mạng số: 283/GP-BTTTT ngày 07 tháng 08 năm 2023'
        },
        mocgame:{
          src:require('../assets/mocLogo.png'),
          address:'Giấy phép cung cấp dịch vụ trò chơi điện tử G1 trên mạng số: 463/GP-BTTTT ngày 28 tháng 10 năm 2019'
        }
      }
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.show =  this.$pathName !== 'muugame'
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.footer {
  font-size: 14px;
  color: #989898;
  padding: 24px 0;
  border-top: 1px solid #ebebeb;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
  p {
    margin: 0;
    line-height: 6.5;
  }
  .fixed {
    bottom: 0;
  }
  .footer-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0 1rem;
  }
  .footer-content.sea {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .footer-content.sea .logo {
    max-height: 50px;
  }

  .footer-content.sea .right {
    margin-right: unset;
    text-align: center;
  }

  .footer-content .right {
    margin-right: 34px;
    padding-top: 4px;
  }

  .logo {
    // height: 42px;
    max-width: 186px;
    height: auto;
  }
}
@media screen and (max-width: 1000px) {
  .footer .footer-content {
    max-width: 90%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    // flex-direction: column;
    align-items: center;
  }
  .footer .footer-content .right {
    margin-right: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    // margin-bottom: 20px;
  }
  .footer p{
    line-height: 1.5;
  }
  .footer .logo {
    // height: 42px;
    max-width: 100%;
    width: 160px;
    height: 100%;
  }
}
</style>
